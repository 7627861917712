<template>
  <BOverlay
    id="overlay-background"
    :show="isUploadingFile"
    variant="tranparent"
    opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <BCard title="배너 정보">
      <BRow>
        <BCol cols="4">
          <BFormGroup label="IDX">
            <BFormInput
              disabled
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="START_AT">
            <TagbyDatetimeInputV2
              v-model="params.start_at"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="END_AT">
            <TagbyDatetimeInputV2
              v-model="params.end_at"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="ORDER_SEQ">
            <vSelect
              v-model="params.order_seq"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="orderSeqOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup label="REDIRECT_TYPE">
            <vSelect
              v-model="params.redirect_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="redirectTypeOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
        <BCol cols="4">
          <BFormGroup
            v-if="params.redirect_type === 'campaign_detail'"
            label="CAMPAIGN_IDX"
          >
            <BFormInput
              v-model="params.redirect_value"
            />
          </BFormGroup>
          <BFormGroup
            v-else-if="params.redirect_type === 'link'"
            label="LINK"
          >
            <BFormInput
              v-model="params.redirect_value"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol cols="4">
          <BFormGroup label="STATE">
            <vSelect
              v-model="params.state"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="stateOptions"
              :reduce="option => option.value"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="TITLE">
            <BFormTextarea
              v-model="params.header_1"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="TEXT">
            <BFormTextarea
              v-model="params.header_2"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup label="IMG">
            <BFormFile
              :placeholder="imgFile != null ? imgFile.file_name : 'Choose a file or drop it here...'"
              accept="image/*"
              @input="$emit('inputImgFile', $event)"
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BImg
            v-if="imgFile"
            :src="imgFile.url"
            fluid
          />
        </BCol>
      </BRow>
    </BCard>
  </BOverlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BOverlay,
  BFormTextarea,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BOverlay,
    BFormTextarea,
    BFormFile,
    BImg,
    vSelect,
    TagbyDatetimeInputV2,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    redirectTypeOptions: {
      type: Array,
      default: () => ([]),
    },
    orderSeqOptions: {
      type: Array,
      default: () => ([]),
    },
    isUploadingFile: {
      type: Boolean,
      default: false,
    },
    imgFile: {
      type: Object,
      default: () => ({}),
    },
    stateOptions: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
