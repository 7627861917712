<template>
  <BRow>
    <BCol
      cols="12"
      xl="8"
      lg="8"
      md="7"
    >
      <BannerInfoCard
        :params="params"
        :redirectTypeOptions="redirectTypeOptions"
        :orderSeqOptions="orderSeqOptions"
        :isUploadingFile="isUploadingFile"
        :imgFile="imgFile"
        :stateOptions="stateOptions"
        @inputImgFile="inputImgFile"
      />
    </BCol>

    <BCol
      cols="12"
      xl="4"
      lg="4"
      md="5"
    >
      <ActionCard
        :is-saving="isSaving"
        :is-valid="isValid"
        @create="create"
      />
    </BCol>
  </BRow>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import viewModel from './viewModel'
import BannerInfoCard from './components/BannerInfoCard.vue'
import ActionCard from './components/ActionCard.vue'

export default {
  components: {
    BRow,
    BCol,

    BannerInfoCard,
    ActionCard,
  },
  setup() {
    return {
      ...viewModel(),
    }
  },
}
</script>
