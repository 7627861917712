import {
  ref,
  onMounted,
  onUnmounted,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useToast from '@/utils/toast'
import uploadFile from '@/utils/uploadFile'
import useResolveValue from '@/utils/resolveValue'
import storeModule from './storeModule'

export default function () {
  const APP_STORE_MODULE_NAME = 'app-widget-banner-create'

  const { makeToast } = useToast()
  const {
    resolveUtcStartDateTime,
    resolveUtcEndDateTime,
  } = useResolveValue()
  const storeDispatch = (name, params) => store.dispatch(`${APP_STORE_MODULE_NAME}/${name}`, params)

  const orderSeqOptions = ref([])

  const fetchOrderSeqList = () => {
    storeDispatch('fetchOrderSeqList').then(response => {
      const resData = response.data.data
      const resLength = resData.length
      const lastOrderSeq = resData[resLength - 1]
      orderSeqOptions.value = resData.map(o => ({
        label: o.display_order_seq,
        value: o.order_seq,
      })).concat([{
        label: lastOrderSeq.display_order_seq + 1,
        value: lastOrderSeq.order_seq + 1,
      }])
    }).catch(() => {
      makeToast('danger', '순서 리스트를 가져오는데 실패했습니다')
    }).finally(() => {
    })
  }

  const params = ref({
    img_idx: null,
    header_1: null,
    header_2: null,
    redirect_type: 'campaign_detail',
    redirect_value: null,
    order_seq: null,
    start_at: null,
    end_at: null,
    state: 'ACTIVE',
  })

  const isSaving = ref(false)
  const isValid = computed(() => {
    const requiredFields = ['img_idx', 'header_1', 'order_seq', 'redirect_type', 'redirect_value']
    return requiredFields.map(f => Boolean(params.value[f])).reduce((p, c) => p && c, true)
  })
  const redirectTypeOptions = [
    { label: '캠페인 상세', value: 'campaign_detail' },
    { label: '링크', value: 'link' },
    { label: '최신 등록 상품', value: 'recent_registered_item' },
  ]
  const isUploadingFile = ref(false)
  const create = () => {
    isSaving.value = true
    storeDispatch('create', {
      ...params.value,
      start_at: resolveUtcStartDateTime(params.value.start_at),
      end_at: resolveUtcEndDateTime(params.value.end_at),
    }).then(() => {
      makeToast('primary', '배너 생성에 성공했습니다.')
      router.replace({
        name: 'app-widget-banner-list',
      }).catch(() => {})
    }).catch(() => {
      makeToast('danger', '배너 생성에 실패했습니다.')
    }).finally(() => {
      isSaving.value = false
    })
  }

  const imgFile = ref()
  const inputImgFile = file => {
    isUploadingFile.value = true
    uploadFile(file, 'banner_info', '')
      .then(response => {
        params.value.img_idx = response.idx
        imgFile.value = {
          idx: response.idx,
          url: response.file_url,
          file_name: response.file_name,
          file_type: response.file_type,
          file_size: response.file_size,
        }
      })
      .catch(() => {
        makeToast('danger', `${file.name} 파일 업로드에 실패했습니다`)
      })
      .finally(() => {
        isUploadingFile.value = false
      })
  }

  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]

  onMounted(() => {
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)
    fetchOrderSeqList()
  })
  onUnmounted(() => {
    if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
  })

  return {
    params,
    isSaving,
    isValid,
    redirectTypeOptions,
    orderSeqOptions,
    isUploadingFile,
    create,

    imgFile,
    inputImgFile,

    stateOptions,
  }
}
